import React, { useState, useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Pagetitle from "../elements/Pagetitle";
import Portfolio from "../elements/Portfolio";

const filters = [
  {
    id: 1,
    text: "Everything",
  },
  {
    id: 2,
    text: "Web Development",
  },
  {
    id: 3,
    text: "Talks",
  },
  {
    id: 4,
    text: "Open Source",
  }
];

const allData = [
  {
    id: 1,
    title: "DoctorLink",
    category: "web development",
    image: "images/works/doctorlink/3.png",
    link: ["https://app.doctorlink.com/register"],
  },
  {
    id: 2,
    title: "Rose Villa",
    category: "web development",
    image: "images/works/rosevilla/1.png",
    popupLink: [
      "images/works/rosevilla/1.png",
      "images/works/rosevilla/2.png",
    ],
  },
  {
    id: 3,
    title: "All Plants",
    category: "web development",
    image: "images/works/allplants/2.png",
    popupLink: [
      "images/works/allplants/1.png",
      "images/works/allplants/2.png",
      "images/works/allplants/4.png",
      "images/works/allplants/5.png",
    ],
  },
  {
    id: 4,
    title: "Your Canteen",
    category: "web development",
    image: "images/works/yourcanteen/1.png",
    popupLink: [
      "images/works/yourcanteen/1.png",
      "images/works/yourcanteen/2.png",
      "images/works/yourcanteen/3.png",
    ],
  },
  {
    id: 5,
    title: "Hillgate",
    category: "web development",
    image: "images/works/hillgate/3.png",
    popupLink: [
      "images/works/hillgate/1.png",
      "images/works/hillgate/2.png",
      "images/works/hillgate/3.png",
    ],
  },
  {
    id: 6,
    title: "Fitsprint",
    category: "web development",
    image: "images/works/fitsprint/1.png",
    popupLink: [
      "images/works/fitsprint/1.png",
      "images/works/fitsprint/2.png",
      "images/works/fitsprint/3.png",
    ],
  },
  {
    id: 7,
    title: "Vehicle tracker prototype",
    category: "web development",
    image: "images/works/vehicletrackerprototype/1.png",
    link: "https://fervent-shaw-bb9a66.netlify.app/",
  },
  {
    id: 8,
    title: "Solari architects",
    category: "web development",
    image: "images/works/solari/1.png",
    link: "https://solari-site.herokuapp.com/",
  },
  {
    id: 9,
    title: "U1st Games",
    category: "web development",
    image: "images/works/u1stgames/1.png",
    popupLink: [
      "images/works/u1stgames/1.png",
      "images/works/u1stgames/2.png",
      "images/works/u1stgames/3.png",
      "images/works/u1stgames/4.png",
    ],
  },
  {
    id: 10,
    title: "Laugh out loud",
    category: "web development",
    image: "images/works/laughoutloud/1.png",
    popupLink: [
      "images/works/laughoutloud/1.png",
      "images/works/laughoutloud/2.png",
      "images/works/laughoutloud/3.png",
      "images/works/laughoutloud/4.png",
    ],
  },
  {
    id: 11,
    title: "Nangpress",
    category: "web development",
    image: "images/works/nangpress/1.png",
    link: "https://printer-john-62541.netlify.app/",
  },
  {
    id: 12,
    title: "Yetibot",
    category: "open source",
    image: "images/works/yetibot/1.png",
    link: "https://yetibot.com/",
  },
  // {
  //   id: 13,
  //   title: "He or she",
  //   category: "web development",
  //   image: "images/works/heorshe/1.png",
  //   popupLink: [
  //     "images/works/heorshe/1.png",
  //     "images/works/heorshe/2.png",
  //     "images/works/heorshe/3.png",
  //     "images/works/heorshe/4.png",
  //   ],
  // },
  {
    id: 13,
    title: "Flappy bird - playcljs",
    category: "open source",
    image: "images/works/playcljsflappybird/1.png",
    link: "https://github.com/oakes/play-cljs-examples",
  },
  {
    id: 14,
    title: "Tainan coding school talk",
    category: "talks",
    image: "images/talks/1.png",
    popupLink: [
      "https://www.youtube.com/watch?v=1xdk5D5J1Hk&feature=youtu.be&ab_channel=LeonTalbert",
    ],
  },
];

function Works() {
  const [getAllItems] = useState(allData);
  const [dataVisibleCount, setDataVisibleCount] = useState(20);
  const [dataIncrement] = useState(3);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  const [noMorePost, setNoMorePost] = useState(false);

  useEffect(() => {
    setActiveFilter(filters[0].text.toLowerCase());
    setVisibleItems(getAllItems.filter((item) => item.id <= dataVisibleCount));
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    setActiveFilter(e.target.textContent.toLowerCase());
    let tempData;
    if (e.target.textContent.toLowerCase() === filters[0].text.toLowerCase()) {
      tempData = getAllItems.filter((data) => data.id <= dataVisibleCount);
    } else {
      tempData = getAllItems.filter(
        (data) =>
          data.category === e.target.textContent.toLowerCase() &&
          data.id <= dataVisibleCount
      );
    }
    setVisibleItems(tempData);
  };

  const handleLoadmore = (e) => {
    e.preventDefault();
    let tempCount = dataVisibleCount + dataIncrement;
    if (dataVisibleCount > getAllItems.length) {
      setNoMorePost(true);
    } else {
      setDataVisibleCount(tempCount);
      if (activeFilter === filters[0].text.toLowerCase()) {
        console.log("they are same");
        setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
      } else {
        setVisibleItems(
          getAllItems.filter(
            (data) => data.category === activeFilter && data.id <= tempCount
          )
        );
      }
    }
  };

  return (
    <section id="works">
      <div className="container">
        <Pagetitle title="Recent Works" />
        {/* Start Portfolio Filters */}
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
        >
          <ul className="portfolio-filter list-inline">
            {filters.map((filter) => (
              <li className="list-inline-item" key={filter.id}>
                <button
                  onClick={handleChange}
                  className={
                    filter.text.toLowerCase() === activeFilter
                      ? "text-capitalize current"
                      : "text-capitalize"
                  }
                >
                  {filter.text}
                </button>
              </li>
            ))}
          </ul>
        </ScrollAnimation>
        {/* End Portfolio Filters */}

        {/* Start Portfolio Items */}
        <div className="row portfolio-wrapper">
          {visibleItems.map((item) => (
            <div className="col-md-4 col-sm-6 grid-item" key={item.id}>
              <Portfolio portfolio={item} />
            </div>
          ))}
        </div>
        {/* End Portfolio Items */}

        <div className="load-more text-center mt-4">
          <button
            className="btn btn-default"
            onClick={handleLoadmore}
            disabled={noMorePost ? "disabled" : null}
          >
            {noMorePost ? (
              "No more items"
            ) : (
              <span>
                <i className="fas fa-spinner"></i> Load more
              </span>
            )}
          </button>
        </div>
      </div>
    </section>
  );
}

export default Works;
