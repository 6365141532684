import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "Gun.io: Professional freelancer",
    years: "March 2020 - Present",
    content:
      "Certified freelance engineer on the Gun.io platform.",
  },
  {
    id: 2,
    title: "Thought & function: Senior software developer",
    years: "June 2017 - March 2020",
    content:
      "As one of the first contractors to work with this agency I played an important role in securing and building good relations with early clients. The majority of my time was spent with DoctorLink, a health tech startup. Here I was the majority contributor to the front-end code, which includes multiple web applications, a full design system and a utility library.",
  },
  {
    id: 3,
    title: "Nang Development: Freelancer",
    years: "March 2014 - Present",
    content:
      "Aside from my agency work I also work with my own clients directly. Please scroll down for details",
  }
];

const experienceData = [
  {
    id: 1,
    title: "U1st Games: Co-founder",
    years: "June 2013 - June 2017",
    content:
        "Achieved 150,000 daily active users. I was responsible for everything on the technology side including coding, devops, architecture and hiring contractors from time to time.",
  },
  {
    id: 2,
    title: "Samsung Electronics: Assistant Engineer",
    years: "March 2012 - July 2012",
    content:
      "Introduced automated UI and integration testing to the team.",
  },
  {
    id: 3,
    title: "University College London: BEng Electronic and Electrical Engineering with Nanotechnology",
    years: "2009 - 2013",
    content:
      "Achieved a 2:1. (approx. 3.5 GPA)",
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
